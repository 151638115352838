import React from 'react';
// @ts-ignore
import videos from './Anke.mp4';

function App() {
    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <video loop controls autoPlay style={{width: '100vw', height: '100vh'}}>
                <source src={videos} type="video/mp4"/>
            </video>
        </div>
    );
}

export default App;
